import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { AxiosResponse } from 'axios';

import { configQueryKeys } from '@apis/configApi';
import {
  getHTTPClient,
  queryClient,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@core/http-client';
import { ConfigCore } from '@shared/models/config';
import { getQueriesAsSearch } from '@shared/utils/common';

import {
  UsersPayload,
  UsersResponse,
  UsersUpdateProfileImagePayload,
  UsersUpdateProfileImageResponse,
  UserUpdatePayload,
} from './usersApi.types';

const $http = getHTTPClient();

export const usersQueryKeys = createQueryKeys('users', {
  all: null,
  list: (payload: UsersPayload) => ({
    queryKey: [payload.query],
    queryFn: async () => (await $http.get<UsersResponse>(`/users${getQueriesAsSearch(payload.query)}`)).data,
  }),
});

export type UsersQueryKeys = inferQueryKeys<typeof usersQueryKeys>;

export const useUsersListQuery = (payload: UsersPayload, options?: UseQueryOptions<UsersResponse>) =>
  useQuery<UsersQueryKeys['list']['queryKey'], UsersResponse>({
    ...usersQueryKeys.list(payload),
    keepPreviousData: true,
    ...options,
  });

export const useUsersUpdateProfileImageMutation = (
  opts?: UseMutationOptions<UsersUpdateProfileImagePayload, UsersUpdateProfileImageResponse>
) =>
  useMutation({
    ...opts,
    mutationFn: async (payload: UsersUpdateProfileImagePayload) =>
      (
        await $http.post<UsersUpdateProfileImagePayload['body'], AxiosResponse<UsersUpdateProfileImageResponse>>(
          `user/${payload.id}/profile_pic`,
          payload.body
        )
      ).data,
  });

export const useUserUpdateMutation = (opts?: UseMutationOptions<UserUpdatePayload, any>) =>
  useMutation({
    ...opts,
    mutationFn: async (payload: UserUpdatePayload) => {
      const response = await $http.put<UserUpdatePayload['body'], AxiosResponse<any>>(
        `users/${payload.id}/update`,
        payload.body
      );

      // Update user
      queryClient.setQueryData<ConfigCore>(configQueryKeys.showAgency().queryKey, (config) => {
        if (config) {
          return {
            ...config,
            user: {
              ...config.user,
              ...response.data,
            },
          };
        }

        return config;
      });
    },
  });
